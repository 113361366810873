import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Platforms } from '../../tv-auth';
import { Client, DEFAULT_PORTAL_SCALE_FACTOR, InFlightState } from '../../tv-auth/client';

export enum AccountPanelScreen {
    Menu = 'Menu',
    Account = 'Account',
    Subscriptions = 'Subscriptions',
    Feedback = 'Feedback',
}

export enum TvAuthScreen {
    List,
    Picker,
    Auth,
}
export type PlatformClientMap = Partial<{ [key in Platforms]: Client }>;
export type PendingTvConnections = Partial<{ [key in Platforms]: string }>;
export type TvAuthPortalViewport = { height: number; width: number; deviceScaleFactor: number };

export interface AccountPanelState {
    screen: AccountPanelScreen;
    tvAuthScreen: TvAuthScreen;
    tvAuthState: InFlightState;
    tvAuthPlatform: Platforms;
    tvAuthClients: PlatformClientMap;
    tvAuthPortalViewport: TvAuthPortalViewport;
    tvAuthPortalId: string;
    tvAuthUsername: string;
    tvAuthUsernameError: boolean;
    tvAuthPassword: string;
    tvAuthPasswordError: boolean;
    tvAuthErrorMessage: string;
    tvAuthFormReady: boolean;
    tvAuthPlatformsEnabled: Partial<{ [platformName: string]: boolean }>;
}

export const initialState: AccountPanelState = {
    screen: null,
    tvAuthScreen: TvAuthScreen.List,
    tvAuthState: null,
    tvAuthPlatform: null,
    tvAuthClients: {},
    tvAuthPortalViewport: {
        height: 640,
        width: 360,
        deviceScaleFactor: DEFAULT_PORTAL_SCALE_FACTOR,
    },
    tvAuthPortalId: null,
    tvAuthUsername: '',
    tvAuthUsernameError: false,
    tvAuthPassword: '',
    tvAuthPasswordError: false,
    tvAuthErrorMessage: '',
    tvAuthFormReady: false,
    tvAuthPlatformsEnabled: {},
};

const accountPanel = createSlice({
    name: 'accountPanel',
    initialState,
    reducers: {
        showAccountPanel(state, action: PayloadAction<AccountPanelScreen>) {
            if (
                state.tvAuthState === InFlightState.Authenticating ||
                state.tvAuthState === InFlightState.Portal
            ) {
                state.screen = AccountPanelScreen.Subscriptions;
                return;
            }

            const screen =
                typeof action.payload === 'undefined' ? AccountPanelScreen.Menu : action.payload;
            state.screen = screen;
        },
        hideAccountPanel(state) {
            state.screen = null;
        },
        setTvAuthScreen(state, action: PayloadAction<TvAuthScreen>) {
            state.tvAuthScreen = action.payload;
        },
        setTvAuthInFlightState(state, action: PayloadAction<InFlightState>) {
            state.tvAuthState = action.payload;
        },
        setTvAuthPlatform(state, action: PayloadAction<Platforms>) {
            state.tvAuthPlatform = action.payload;
        },
        setTvAuthClients(state, action: PayloadAction<PlatformClientMap>) {
            state.tvAuthClients = action.payload;
        },
        setTvAuthPortalViewport(state, action: PayloadAction<TvAuthPortalViewport>) {
            state.tvAuthPortalViewport = action.payload;
        },
        setTvAuthPortalId(state, action: PayloadAction<string>) {
            state.tvAuthPortalId = action.payload;
        },
        setTvAuthUsername(state, action: PayloadAction<string>) {
            state.tvAuthUsername = action.payload;
        },
        setTvAuthUsernameError(state, action: PayloadAction<boolean>) {
            state.tvAuthUsernameError = action.payload;
        },
        setTvAuthPassword(state, action: PayloadAction<string>) {
            state.tvAuthPassword = action.payload;
        },
        setTvAuthPasswordError(state, action: PayloadAction<boolean>) {
            state.tvAuthPasswordError = action.payload;
        },
        setTvAuthErrorMessage(state, action: PayloadAction<string>) {
            state.tvAuthErrorMessage = action.payload;
        },
        setTvAuthFormReady(state, action: PayloadAction<boolean>) {
            state.tvAuthFormReady = action.payload;
        },
        setEnabledTvAuthPlatforms(state, action: PayloadAction<{ [name: string]: boolean }>) {
            state.tvAuthPlatformsEnabled = action.payload;
        },
    },
});

export const {
    showAccountPanel,
    hideAccountPanel,
    setTvAuthScreen,
    setTvAuthInFlightState,
    setTvAuthPlatform,
    setTvAuthClients,
    setTvAuthPortalViewport,
    setTvAuthUsername,
    setTvAuthUsernameError,
    setTvAuthPassword,
    setTvAuthPasswordError,
    setTvAuthErrorMessage,
    setTvAuthFormReady,
    setTvAuthPortalId,
    setEnabledTvAuthPlatforms,
} = accountPanel.actions;

export default accountPanel.reducer;
